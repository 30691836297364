import moment from 'moment';
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';

/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Icon } from '@blueprintjs/core';
import { grey } from "material-colors";

const PostBox =  (props) => {

    // Props destructure
    const { date, title, description, author, slug, image, t, baseUrlImage, timeToRead} = props;
    const { i18n } = useTranslation();

    // Post box height
    const minHeight = 506;

    // Post box styles
    const postBoxStyle = {
        wrapper: css`
            transition: all 0.2s;
            width: 100%;              
            @media (min-width: 768px) {
                width: 50%;
            }                
        `,
        content: {
            position: 'relative',
            display: 'block',
            textDecoration: 'none',
            transition: 'all 0.2s',
            boxShadow: '0 0 18px 1px rgba(46,92,166, 0.15)',
            color: 'black',
            overflow: 'hidden',
            margin: 10,
            borderRadius: 3,
            minHeight: minHeight,
            '&:hover': {
                color: 'black',
                textDecoration: 'none',
                transform: 'scale(1.015)',
                boxShadow: '0 0 18px 9px rgba(46,92,166, 0.15)'
            }
        },
        image: {
            objectFit:'cover',
            width: '100%',
            height: 200,
            backgroundColor: 'rgba(46,92,166, 0.3)'
        },
        title: {
            width: '100%',
            padding: '10px 20px',
            color: '#2E5CA6',
            fontWeight: 'bold',
            fontSize: 24
        },
        description: {
            padding: '0px 20px 70px',
            fontSize: 16,
            textAlign: 'justify'
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            padding: '10px 20px',
            fontSize: 12,
            author: {
                fontWeight: 'bold'
            },
            date: {}
        },
        readMore: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: '10px 20px',
            fontSize: 14,
            color: '#2E5CA6',
            fontWeight: 'bold'
        },
        timeToRead: {
            position: 'absolute',
            bottom: 0,
            right: 100,
            padding: '10px 20px',
            fontSize: 14,
            color: grey[800],
            fontWeight: 'bold'
        }
    };

    return (
        <div css={postBoxStyle.wrapper}>
            <Link to={i18n.language === "en" ? "/en/blog/" + slug + "/" : "/es/blog/" + slug + "/"} css={postBoxStyle.content}>
                <img style={postBoxStyle.image} src={baseUrlImage+image} alt={title} />
                <div style={postBoxStyle.title}>
                    {title}
                </div>
                <div style={postBoxStyle.description}>
                    {description}
                </div>
                <div style={postBoxStyle.footer}>
                    <div style={postBoxStyle.footer.author}>
                        {author}
                    </div>
                    <div style={postBoxStyle.footer.date}>
                        {moment(date).format('LL')}
                    </div>
                </div>
                <div style={postBoxStyle.timeToRead}>
                        <Icon
                            icon="stopwatch"
                            iconSize={20}
                        /> {timeToRead} min
                </div>
                <div style={postBoxStyle.readMore}>
                    {t('blog.readMore').toUpperCase()}
                </div>
            </Link>
        </div>
    );
};

export default PostBox;