/** @jsx jsx */
import { jsx } from '@emotion/react';

import config from '../../../gatsby-config';
import headerImg from '../../../static/img/laptop-blog.jpg';

const HeaderSection =  (props) => {
        const { t } = props;

        return (
            <header
                id="headerSection"
                key="header"
                css={styles.mainSection}
            >
                <div className="container-fluid pt30">
                    <div className="row text-center">
                        <div className="col-12">
                            <h1 style={{ color: 'white' }}>{t('blog.title')}</h1>
                        </div>
                    </div>
                </div>
            </header>
        )
}

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        // background: 'linear-gradient(#2E5CA6, #2E5CA6)'
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        height: 270,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    }
};

export default HeaderSection;