import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import MainLayout from '../../components/main-layout';
import PostBox from './post-box';
import HeaderSection from './header-section';
import QueryPosts from './queryPosts';
import gatsbyConfig from '../../../gatsby-config';

const Blog =  (props) => {
    const { pageContext } = props;
    const { t, i18n } = useTranslation();
    const blogResponse = QueryPosts();
    const blogArticles = blogResponse.allStrapiArticle.edges;

    // We sort the blog articles by publication date, starting with most recent dates
    const sortedBlogArticles = blogArticles.sort(function(a, b) {
        a = new Date(a.node.DatePublished);
        b = new Date(b.node.DatePublished);
        return a > b ? -1 : a < b ? 1 : 0;
    });

    const blogStyle = {
        display: 'flex',
        flexWrap: 'wrap'
    };
    const strapiUrl = gatsbyConfig.siteMetadata.strapiUrl;

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <HeaderSection key="header" t={t} />
            <div key="container" className="container mt30 mb30">
                <div className="row">
                    <div className="col-12">
                        <div style={blogStyle}>
                            {
                                sortedBlogArticles?.map( (post) => {
                                    const blogPostImage = post.node.image?.url;
                                    let postBox = null;

                                    // One postbox for each language
                                    switch(i18n.language) {
                                        case "es":
                                            if(post.node.localizations?.data[0]) {
                                                postBox = (
                                                    <PostBox
                                                        key={post.node.id}
                                                        title={post.node.localizations?.data[0]?.attributes?.title}
                                                        date={post.node.localizations?.data[0]?.attributes?.DatePublished}
                                                        description={post.node.localizations?.data[0]?.attributes?.description}
                                                        category={t(`blog.category.${post.node.category}`)}
                                                        author={post.node.writer?.Name}
                                                        slug={post.node.localizations?.data[0]?.attributes?.slug}
                                                        image={blogPostImage}
                                                        t={t}
                                                        baseUrlImage={strapiUrl}
                                                        timeToRead={post.node.childStrapiArticleContentTextnode?.childMarkdownRemark?.timeToRead}
                                                    />
                                                ) 
                                            }
                                            break;

                                        // This is the default language (on strapi) (english)
                                        default:
                                            postBox = (
                                                <PostBox
                                                    key={post.node.id}
                                                    title={post.node.title}
                                                    date={post.node.DatePublished}
                                                    description={post.node.description}
                                                    category={t(`blog.category.${post.node.category}`)}
                                                    author={post.node.writer?.Name}
                                                    slug={post.node.slug}
                                                    image={blogPostImage}
                                                    t={t}
                                                    baseUrlImage={strapiUrl}
                                                    timeToRead={post.node.childStrapiArticleContentTextnode?.childMarkdownRemark?.timeToRead}
                                                />
                                            );
                                        break;
                                                
                                    } 
                                    
                                    return postBox;                                  
                                }) || t('productUpdates.noContentLabel')
                            }
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default Blog;