import { graphql, useStaticQuery } from "gatsby";

export default function QueryPosts() {
    const result = useStaticQuery(graphql`
        query {
            allStrapiArticle {
                edges {
                    node {
                        childStrapiArticleContentTextnode {
                            childMarkdownRemark {
                              timeToRead
                            }
                        }
                        localizations {
                            data {
                                attributes {
                                    DatePublished
                                    content
                                    description
                                    locale
                                    slug
                                    title
                                }
                            }
                        }
                        image {
                            url
                        }
                        id
                        title
                        description
                        content {
                            data {
                                content
                            }
                        }
                        locale
                        DatePublished
                        writer {
                            Name
                        }
                        slug
                    }
                }
            }
        }`
    )
    return result
};